import { watchEffect, ref } from 'vue';
import moment from 'moment';
export default {
  props: {
    modelValue: Number,
    label: String,
    rules: Array,
    fieldProps: Object,
    pickerProps: Object
  },

  setup(props, {
    emit
  }) {
    const current = ref([]);
    const show = ref(false);
    const currentText = ref('');
    watchEffect(() => {
      current.value = props.modelValue ? moment(props.modelValue).format('YYYY-MM-DD').split('-') : [];
      currentText.value = props.modelValue ? moment(props.modelValue).format('YYYY-MM-DD') : '';
    });

    const selectDate = () => {
      show.value = true;
    };

    const confirm = ({
      selectedValues
    }) => {
      current.value = selectedValues;
      show.value = false;
      const date = new Date(current.value).valueOf() / 1000;
      emit('update:modelValue', date);
    };

    return {
      current,
      show,
      currentText,
      selectDate,
      confirm
    };
  }

};