import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_field = _resolveComponent("van-field");

  const _component_van_date_picker = _resolveComponent("van-date-picker");

  const _component_van_popup = _resolveComponent("van-popup");

  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_van_field, _mergeProps({
    modelValue: $setup.currentText,
    label: $props.label,
    placeholder: "请选择",
    required: "",
    rules: $props.rules,
    readonly: "",
    "right-icon": "arrow"
  }, $props.fieldProps, {
    onClickInput: $setup.selectDate
  }), null, 16, ["modelValue", "label", "rules", "onClickInput"]), _createVNode(_component_van_popup, {
    title: '选择' + $props.label,
    show: $setup.show,
    "onUpdate:show": _cache[2] || (_cache[2] = $event => $setup.show = $event),
    position: "bottom"
  }, {
    default: _withCtx(() => [_createVNode(_component_van_date_picker, _mergeProps({
      modelValue: $setup.current,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $setup.current = $event),
      type: "date"
    }, $props.pickerProps, {
      onConfirm: $setup.confirm,
      onCancel: _cache[1] || (_cache[1] = $event => $setup.show = false)
    }), null, 16, ["modelValue", "onConfirm"])]),
    _: 1
  }, 8, ["title", "show"])]);
}