import { createRouter, createWebHashHistory } from 'vue-router'
import store from '../modules'
import { showNotify } from 'vant'

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes: [
        { path: '/', redirect: '/businessConsole' },
        { path: '/noAgent', meta: { name: '暂无商户' }, component: () => import('@/views/frame/noAgent.vue') }
    ]
})

router.beforeResolve((to) => {
    const { user } = store.state
    let c1 = to.query.companyid
    let c2 = user.companyid
    if (Array.isArray(c1)) c1 = c1[0]
    if (c1 && c2 && c1 != c2) store.commit('user/set_loginout')
    const companyid = c1 || c2
    if (user.login) {
        if (to.matched.length === 0) {
            showNotify({ message: '未找到路由!', type: 'danger' })
            return false
        }
        document.title = to.meta.name
        return true
    } else {
        const token = to.query.token || user.token
        if (token) {
            return store.dispatch('user/auth', { token, companyid }).then(
                () => {
                    delete to.query.token
                    delete to.query.companyid
                    router.addRoute(user.route)
                    router.replace({ path: to.path, query: to.query })
                },
                (err) => {
                    console.log(err)
                    if (err?.code === 101) {
                        store.dispatch('user/login', companyid)
                    } else {
                        router.push('/noAgent')
                    }
                }
            )
        } else {
            store.dispatch('user/login', companyid)
        }
    }
})

export default router
