import OSS from 'ali-oss'
import api from './api'
import store from '@/modules'
import util from './util'

const client = new OSS(api.ossConfig)

export default async (file) => {
    let error, result
    const { companyid, agent } = store.state.user
    try {
        const now = new Date()
        const today = now.toLocaleDateString().replace(/\//g, '-')
        const floder = `${api.ossConfig.floder}/${companyid}/${agent.id}/${today}`
        const path = `${floder}/${util.guid()}_${file.name}`
        file.status = 'uploading'
        await client.multipartUpload(path, file, { progress: (p) => (file.percent = p * 100) })
        file.url = `${api.imageBaseUrl}/${path}`
        file.status = 'done'
        return file
    } catch (e) {
        error = e
        file.status = 'error'
        console.error(e)
    }
    return [error, result]
}
