import { reactive } from 'vue';
export default {
  props: {
    type: String
  },

  setup(props) {
    const page = reactive({});

    if (props.type === 'no-data') {
      page.src = '/static/image/no-data.png';
    } else if (props.type === 'no-network') {
      page.src = '/static/image/no-network.png';
    } else if (props.type === 'no-search') {
      page.src = '/static/image/no-search.png';
    }

    return {
      page
    };
  }

};