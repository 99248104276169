import axios from 'axios'
// import api from './api'
import store from '@/modules'
import { showNotify, closeToast } from 'vant'
import router from '@/router'

// const http = axios.create({ baseURL: api.apiUrl })
const http = axios.create({ baseURL: '/shopwap/' })

const err = (msg) => Promise.reject(msg)

http.interceptors.request.use(async (config) => {
    config.headers.submittype = 'shopwap'
    if (config.auth !== false) {
        const { token, companyid, agent } = store.state.user
        if (!token || !companyid) return err('无token或companyid')
        config.headers.Authen = token
        if (!config.params) config.params = {}
        config.params.companyid = companyid
        if (agent) config.params.shop_id = agent.id
    }
    return config
})

http.interceptors.response.use(
    (response) => {
        const data = response.data
        const { custom } = response.config
        if (data.code !== 0) {
            if (data.code === 104) {
                console.log(data)
                const { companyid, token } = store.state.user
                http.post('Agent/agentList', { companyid }, { headers: { Authen: token }, auth: false }).then(
                    (payload) => {
                        store.commit('user/set_agents', payload)
                        store.commit('user/set_popup', true)
                    },
                    () => {
                        router.push('/noAgent')
                    }
                )
            } else if (!custom || custom.toast !== false) {
                showNotify({ message: data.message, type: 'danger' })
                closeToast()
            } else if (data.code === 101) {
                store.commit('user/set_loginout')
            }
            return err(data)
        }
        return data.result || data.data || {}
    },
    (response) => err(response)
)

export default http
