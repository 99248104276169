import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-72c76bba"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "iconfont y-iconfont"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("i", {
    class: _normalizeClass($props.name),
    style: _normalizeStyle({
      fontSize: $props.size + 'px',
      color: $props.color
    })
  }, null, 6)]);
}