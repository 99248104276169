import { reactive } from 'vue';
export default {
  props: {
    modelValue: {
      type: [Number, String]
    },
    dict: {
      type: [Object, Array, Function]
    },
    label: {
      type: String,
      default: ''
    },
    fieldText: {
      type: String,
      default: 'name'
    },
    fieldValue: {
      type: String,
      default: 'id'
    },
    required: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => []
    },
    show_name: {
      type: String,
      default: ''
    }
  },

  setup(props, {
    emit
  }) {
    const popup = reactive({
      show: false,
      show_name: '',
      value: '',
      data: []
    });

    const handlerShow = async () => {
      popup.show = true;

      if (popup.data.length === 0) {
        if (typeof props.dict === 'function') {
          popup.data = await props.dict();
        } else if (Array.isArray(props.dict)) {
          popup.data = props.dict;
        } else if (typeof props.dict === 'object') {
          for (let key in props.dict) {
            popup.data.push({
              name: props.dict[key],
              id: key
            });
          }
        }
      }
    };

    const confirm = ({
      selectedOptions: [item]
    }) => {
      popup.show = false;
      popup.show_name = item[props.fieldText];
      emit('update:modelValue', item[props.fieldValue]);
    };

    return {
      popup,
      handlerShow,
      confirm
    };
  }

};