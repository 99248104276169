import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "m-t-16"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_uploader = _resolveComponent("van-uploader");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_uploader, _mergeProps({
    modelValue: $data.files,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.files = $event),
    "after-read": $options.afterRead,
    "before-delete": $options.beforeDelete
  }, $props.config), null, 16, ["modelValue", "after-read", "before-delete"])]);
}