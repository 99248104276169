import { mapState } from 'vuex'

export default {
    install(app) {
        app.mixin({
            computed: {
                ...mapState({
                    agent: (state) => state.user.agent,
                }),
            },
        })
    },
}
