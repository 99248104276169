import store from './index'
import api from '@/common/api'
import { stringifyQuery } from 'vue-router'
import http from '@/common/http'
import config from '@/common/config'
const local = JSON.parse(localStorage.getItem('local')) || {}

store.registerModule('user', {
    namespaced: true,
    state: {
        login: false,
        companyid: local.companyid,
        token: local.token,
        menus: [],
        user: null,
        // 用户所在的代理商列表
        agents: [],
        // 当前代理商
        agent: null,
        // 选择商户
        popup: false
    },
    mutations: {
        set_login(state, { token, companyid }) {
            state.login = true
            state.token = token
            state.companyid = companyid
            state.route = {
                path: '/',
                component: () => import('../views/frame/FrameLayout.vue'),
                children: config.createMenu(config.baseMenus)
            }
            let agent
            if (local.agentid) agent = state.agents.find((item) => item.id === local.agentid)
            state.agent = agent || state.agents[0]
            localStorage.setItem('local', JSON.stringify({ token, companyid, agentid: agent?.id }))
        },
        set_loginout(state) {
            state.login = false
            state.token = null
            state.companyid = null
            localStorage.removeItem('local')
        },

        set_agents(state, payload) {
            state.agents = payload
        },

        set_agent(state, payload) {
            state.agent = payload
            localStorage.setItem('local', JSON.stringify({ token: state.token, companyid: state.companyid, agentid: payload.id }))
            if(payload.noReload) return
            location.reload()
        },

        set_popup(state, payload) {
            state.popup = payload
        }
    },
    actions: {
        login(_, companyid) {
            const query = {
                redirect_url: encodeURIComponent(location.href),
                companyid
            }
            location.href = `${api.apiUrl}Login/index?` + stringifyQuery(query)
        },
        async auth(store, { token, companyid }) {
            return http.post('Agent/agentList', { companyid }, { headers: { Authen: token }, auth: false }).then((payload) => {
                store.commit('set_agents', payload)
                store.commit('set_login', { token, companyid })
            })
        }
    }
})
