import store from './index'
import http from '@/common/http'

store.registerModule('refund', {
  namespaced: true,
  state: {
    orderList: {},
    orderInfo: {
      auditsInfo: ''
    },
  },
  mutations: {
    set_orderList(state, payload) {
			state.orderList = payload
		},

    set_orderDetail(state, payload) {
			state.orderInfo = payload
    }
  },
  actions: {
    // 订单列表
    post_orderList(store, params) {
      return http.post('ShareQuestion/index', params).then(payload=> store.commit('set_orderList', payload))
    },

    // 订单详情
    post_orderDetail(store, params) {
      return http.post('ShareQuestion/info', params).then(payload=> store.commit('set_orderDetail', payload))
    },

    // 审核驳回
    post_reject(store, params) {
      return http.post('ShareQuestion/reject', params).then(payload=> store.commit('set_orderDetail', payload))
    },

    // 审核同意
    post_returnMoney(store, params) {
      return http.post('ShareQuestion/returnMoney', params).then(payload=> store.commit('set_orderDetail', payload))
    },

    // 退款图片
    post_wechatImg(store, params) {
      return http.post('ShareQuestion/wechatImg', params, { headers: { 'Content-Type': 'multipart/form-data', } })
    },
    
  }
})
