import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  style: {
    "min-height": "400px"
  },
  flex: "cross:center"
};
const _hoisted_2 = {
  class: "text-center"
};
const _hoisted_3 = {
  class: "m-t-32"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_image = _resolveComponent("van-image");

  const _component_van_icon = _resolveComponent("van-icon");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [$setup.page.src ? (_openBlock(), _createBlock(_component_van_image, {
    key: 0,
    src: $setup.page.src
  }, null, 8, ["src"])) : _createCommentVNode("", true), $setup.page.icon ? (_openBlock(), _createBlock(_component_van_icon, {
    key: 1,
    name: $setup.page.icon
  }, null, 8, ["name"])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_3, _toDisplayString($setup.page.message), 1)])]);
}