import store from './index'
import http from '@/common/http'

store.registerModule('equipment', {
  namespaced: true,
  state: {
    clientList: {},
    clientInfo: {}
  },
  mutations: {
    set_clientList(state, payload) {
			state.clientList = payload
		},

    set_clientEditInfo(state, payload) {
			state.clientInfo = payload
    }
  },
  actions: {
    // 设备列表
    get_clientList(store, params) {
      return http.get('Equipment/clientList', { params }).then(payload=> store.commit('set_clientList', payload))
    },

    // 重启设备
    post_clientList(store, params) {
      return http.post('Equipment/updateC', params)
    },

    // 标准码
    get_clientQrcode(store, params) {
      return http.get('Equipment/clientQrcode', { params })
    },

    // 宣传码
    get_clientPub(store, params) {
      return http.get('Equipment/clientPub', { params })
    },

    // 设备详情
    get_clientEditInfo(store, params) {
      return http.get('Equipment/clientEditInfo', { params }).then(payload=> store.commit('set_clientEditInfo', payload))
    },

    // 编辑设备详情
    post_clientEditBase(store, params) {
      return http.post('Equipment/clientEditBase', params)
    },

    // 编辑价格
    post_clientEditPrice(store, params) {
      return http.post('Equipment/clientEditPrice', params)
    },    

    // 恢复出厂价格
    post_clientEditInitPrice(store, params) {
      return http.post('Equipment/clientEditInitPrice', params)
    },
    
    // 同步价格
    post_syncPrice(store, params) {
      return http.post('Equipment/syncPrice', params)
    },

    // 投放场景
    get_getPushTypeSelect(store, params) {
      return http.get('Equipment/getPushTypeSelect', { params })
    },
  }
})
