const config = {
    development: {
        apiUrl: 'https://dev.jsshareprint.com/shopwap/',
         // 阿里云OSS配置
         ossConfig: {
            // 阿里云OSS的AccessKeyId
            accessKeyId: 'LTAI4G6EcrH7Uw8T6aWEte1E',
            // 阿里云OSS的AccessKeySecret
            accessKeySecret: 'lZsPQ5sztdkG5wcYkzTR4VQF7hKcR4',
            // 阿里云OSS的Bucket
            bucket: 'dev-test-yinboshi',
            // 阿里云OSS的Region
            region: 'oss-cn-beijing',
            // 阿里云OSS的文件夹
            floder: 'WEIXIN/dev'
        },
         // 图片地址(静态资源地址)
         imageBaseUrl: 'https://dev-test-yinboshi.oss-cn-beijing.aliyuncs.com'
    },

    testing: {
        apiUrl: 'http://demo.jsshareprint.com/shopwap/',
        ossConfig: {
            accessKeyId: 'LTAI4G6EcrH7Uw8T6aWEte1E',
            accessKeySecret: 'lZsPQ5sztdkG5wcYkzTR4VQF7hKcR4',
            bucket: 'dev-test-yinboshi',
            region: 'oss-cn-beijing',
            floder: 'WEIXIN/test'
        },
        imageBaseUrl: 'https://dev-test-yinboshi.oss-cn-beijing.aliyuncs.com'
    },

    production: {
        apiUrl: 'http://home.jsyinba.com/shopwap/',
        ossConfig: {
            accessKeyId: 'LTAI4G6EcrH7Uw8T6aWEte1E',
            accessKeySecret: 'lZsPQ5sztdkG5wcYkzTR4VQF7hKcR4',
            bucket: 'js-print-data',
            region: 'oss-cn-beijing',
            floder: 'WEIXIN/prod'
        },
        imageBaseUrl: 'https://js-print-data.oss-cn-beijing.aliyuncs.com'
    }
}

const api = config[process.env.NODE_ENV]


export default api
