import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-54eaa4d5"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "w-100",
  flex: "main:justify cross:center"
};
const _hoisted_2 = {
  key: 0,
  class: "c-gray"
};
const _hoisted_3 = ["multiple"];
const _hoisted_4 = {
  key: 0,
  class: "w-100 p-t-16 p-b-16"
};
const _hoisted_5 = {
  flex: "main:justify cross:center box:last"
};
const _hoisted_6 = {
  flex: "cross:center"
};
const _hoisted_7 = {
  class: "m-l-24 f-24"
};
const _hoisted_8 = {
  class: "w-px-400",
  style: {
    "word-break": "break-all"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_icon = _resolveComponent("van-icon");

  const _component_van_image = _resolveComponent("van-image");

  const _component_van_progress = _resolveComponent("van-progress");

  const _component_van_field = _resolveComponent("van-field");

  return _openBlock(), _createBlock(_component_van_field, {
    modelValue: $props.modelValue,
    "label-align": "top",
    required: $props.required,
    "input-align": "left"
  }, {
    label: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", null, _toDisplayString($props.label), 1), $setup.list.length < $props.count ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("input", {
      class: "input",
      type: "file",
      accept: "image/*",
      onChange: _cache[0] || (_cache[0] = (...args) => $setup.onAfterRead && $setup.onAfterRead(...args)),
      multiple: $props.count - $setup.list.length > 1
    }, null, 40, _hoisted_3), _createTextVNode(" " + _toDisplayString($props.placeholder), 1), _createVNode(_component_van_icon, {
      name: "arrow"
    })])) : _createCommentVNode("", true)])]),
    input: _withCtx(() => [$setup.list.length ? (_openBlock(), _createElementBlock("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.list, (item, i) => {
      return _openBlock(), _createElementBlock("div", {
        class: "m-t-16 m-b-16 w-100",
        key: i
      }, [_createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createVNode(_component_van_image, {
        width: 60,
        height: 60,
        fit: "contain",
        radius: 8,
        src: item.url + '?x-oss-process=image/resize,w_60/format,jpeg',
        onClick: $event => $setup.previewImage(i)
      }, null, 8, ["src", "onClick"]), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, _toDisplayString(item.name), 1), _createElementVNode("div", null, _toDisplayString((item.size / 1024).toFixed(2)) + "KB", 1)])]), _createVNode(_component_van_icon, {
        class: "c-primary",
        size: 24,
        name: "clear",
        onClick: $event => $setup.onRemove(i)
      }, null, 8, ["onClick"])]), item.status === 'uploading' ? (_openBlock(), _createBlock(_component_van_progress, {
        key: 0,
        class: "m-t-16",
        percentage: item.percent
      }, null, 8, ["percentage"])) : _createCommentVNode("", true)]);
    }), 128))])) : _createCommentVNode("", true)]),
    _: 1
  }, 8, ["modelValue", "required"]);
}