import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './modules/modules'
import Vant from 'vant'
import 'vant/lib/index.css'
import 'flex.css/dist/flex.css'

import components from '@/components'
import extend from '@/common/extend'

import 'vant/es/toast/style';
import 'vant/es/notify/style';
import 'vant/es/image-preview/style';
import 'vant/es/dialog/style';

createApp(App).use(store).use(router).use(Vant).use(components).use(extend).mount('#app')
