import { reactive, watchEffect } from 'vue';
import http from '@/common/http';
let promise;
export default {
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    show_name: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: '所在地区'
    },
    required: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => []
    }
  },

  setup(props, {
    emit
  }) {
    const popup = reactive({
      show: false,
      show_name: '',
      value: '',
      data: []
    });
    watchEffect(() => {
      popup.value = props.modelValue;
    });

    const getArea = async code => {
      const payload = await http.get('getArea', {
        params: {
          pcode: code
        }
      });
      return payload.list;
    };

    const handlerShow = async () => {
      popup.show = true;
      if (!promise) promise = getArea();
      popup.data = await promise;
    };

    const onFinish = item => {
      const node = item.selectedOptions[item.tabIndex];
      popup.show = false;
      popup.show_name = node.show_name;
      emit('update:modelValue', item.value);
    };

    const onChange = async item => {
      const node = item.selectedOptions[item.tabIndex];

      if (node.level < 3) {
        if (node.children) return;
        node.children = [];
        const list = await getArea(item.value);

        if (list.length) {
          node.children = list;
        } else {
          onFinish(item);
        }
      }
    };

    return {
      handlerShow,
      onChange,
      onFinish,
      popup
    };
  }

};