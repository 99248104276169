import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_field = _resolveComponent("van-field");

  const _component_van_cascader = _resolveComponent("van-cascader");

  const _component_van_popup = _resolveComponent("van-popup");

  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_van_field, {
    modelValue: $setup.popup.show_name || $props.show_name,
    "is-link": "",
    label: $props.label,
    required: $props.required,
    onClick: $setup.handlerShow,
    readonly: "",
    rules: $props.rules
  }, null, 8, ["modelValue", "label", "required", "onClick", "rules"]), _createVNode(_component_van_popup, {
    show: $setup.popup.show,
    "onUpdate:show": _cache[2] || (_cache[2] = $event => $setup.popup.show = $event),
    round: "",
    position: "bottom"
  }, {
    default: _withCtx(() => [_createVNode(_component_van_cascader, {
      modelValue: $setup.popup.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $setup.popup.value = $event),
      title: '请选择' + $props.label,
      options: $setup.popup.data,
      onClose: _cache[1] || (_cache[1] = $event => $setup.popup.show = false),
      "field-names": {
        text: 'name',
        value: 'code'
      },
      onChange: $setup.onChange,
      onFinish: $setup.onFinish
    }, null, 8, ["modelValue", "title", "options", "onChange", "onFinish"])]),
    _: 1
  }, 8, ["show"])]);
}