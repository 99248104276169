import "core-js/modules/es.array.includes.js";
import { ref, watch } from 'vue';
import upload from '@/common/upload';
import config from '@/common/config';
import { showImagePreview } from 'vant';
export default {
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    count: {
      type: Number,
      default: 1
    },
    placeholder: {
      type: String,
      default: '请上传'
    }
  },

  setup(props, {
    emit
  }) {
    const list = ref([]);
    watch(() => props.modelValue, () => {
      if (!props.modelValue) {
        list.value = [];
        return;
      }

      const urls = props.modelValue.split(',').filter(item => item);
      urls.map(item => {
        const file = list.value.find(file => file?.url === item);

        if (!file) {
          list.value.push({
            url: item,
            name: item.split('/').pop(),
            size: 0,
            status: 'done'
          });
        }
      });
    }, {
      immediate: true
    });

    const change = () => {
      emit('update:modelValue', list.value.filter(item => item.status === 'done').map(item => item.url).join(','));
    };

    const onAfterRead = async event => {
      let files = [...event.target.files];
      files = files.slice(0, props.count - list.value.length);
      files = files.filter(file => file.type.includes('image') && file.size <= config.ossMaxSize * 1024 * 1024 && file.size >= 1 && !list.value.includes(item => item.name === file.name));
      if (!files.length) return;
      list.value = [...list.value, ...files];
      await Promise.all(files.map(file => upload(file)));
      change();
    };

    const onRemove = index => {
      list.value.splice(index, 1);
      change();
    };

    const previewImage = i => {
      showImagePreview({
        images: list.value.map(item => item.url + '?x-oss-process=image/resize,w_800/format,jpeg'),
        startPosition: i
      });
    };

    return {
      list,
      onAfterRead,
      onRemove,
      previewImage
    };
  }

};