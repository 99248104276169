const config = {
    ossMaxSize: 1024 * 1024 * 50, // 50M

    baseMenus: [
        { name: '商户运营数据', path: 'businessConsole' },
        { name: '设备运营', path: 'deviceConsole' },
        { name: '店铺监控', path: 'shopConsole' },
        {
            name: '财务管理',
            path: 'finance',
            children: [
                { name: '财务提现', path: 'withdrawal' },
                { name: '手动提现', path: 'manualWithdrawal' }
            ]
        },
        {
            name: '设备管理',
            path: 'device',
            children: [
                { name: '设备管理', path: 'device' },
                { name: '打印码', path: 'qrcode' },
                { name: '设备信息', path: 'info' },
                { name: '墨水容量', path: 'inkCapacity' },
                {
                    name: '设备价格',
                    path: 'price',
                    children: [
                        { name: '价格设置', path: 'priceSet' },
                        { name: '平台推荐价格', path: 'recommend' },
                        { name: '同步价格', path: 'synchronous' },
                        { name: '修改价格', path: 'reSet' }
                    ]
                }
            ]
        },
        {
            name: '退款管理',
            path: 'refund',
            children: [
                { name: '退款订单', path: 'orders' },
                { name: '退款订单', path: 'refund' },
                { name: '退款详情', path: 'refundInfo' },
                { name: '订单详情', path: 'orderDetail' }
            ]
        },
        {
            name: '报单',
            path: 'declaration',
            children: [
                { name: '我的报单', path: 'my' },
                { name: '报单申请', path: 'apply' },
                { name: '报单信息', path: 'detail' },
                { name: '操作记录', path: 'operate' },
                { name: '选择代理商', path: 'agent' }
            ]
        }
    ],

    createMenu(menus, path = '', routers = []) {
        menus.map((item) => {
            const _path = `${path}/${item.path}`
            if (item.children) {
                config.createMenu(item.children, _path, routers)
            } else {
                routers.push({
                    path: _path,
                    meta: { name: item.name },
                    component: () => import(`@/views/pages${_path}.vue`)
                })
            }
        })
        return routers
    },
    // 正则，用于校验
    pattern: {
        // 手机号（支持手机号码，3-4位区号，7-8位直播号码，1－4位分机号）
        phone: /^(\d{11})|^((\d{7,8})|(\d{4}|\d{3})-(\d{7,8})|(\d{4}|\d{3})-(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1})|(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1}))$/
    }
}

export default config
