export default {
    install(app) {
        const files = require.context('./common', false, /\.vue$/)
        files.keys().map((path) => {
            const comp = files(path)
            const name = comp.name || path.replace(/\.\/(.*)\.vue/, '$1')
            app.component(name, comp.default || comp)
        })
    },
}
