import upload from '@/common/upload';
export default {
  props: {
    modelValue: String,
    config: Object
  },

  data() {
    return {
      files: [],
      current: ''
    };
  },

  methods: {
    async afterRead(item) {
      item.status = 'uploading';
      item.message = '上传中...';
      const payload = await upload(item.file);

      if (!payload) {
        item.status = 'failed';
        item.message = '上传失败';
        console.error(item);
        return;
      } else {
        item.status = 'finish';
        item.url = payload.url;
        item.path = payload.name;
        this.emit();
      }
    },

    beforeDelete(item, node) {
      // if (item.path) deleteOss(item.path)
      this.files.splice(node.index, 1);
      this.emit();
    },

    emit() {
      const files = this.files.filter(item => item.status === 'finish');
      this.current = files.map(item => item.url).join(',');
      this.$emit('update:modelValue', this.current);
    }

  },

  created() {
    this.$watch('modelValue', {
      handler: value => {
        if (value !== this.current) {
          this.files = value.split(',').map(item => ({
            url: item,
            status: 'finish'
          }));
        }
      },
      immediate: true
    });
  }

};